import React, { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'

function Thing() {
  const ref = useRef()
  useFrame(() => (ref.current.rotation.x = ref.current.rotation.y += 0.001))
  return (
    <>
      <mesh ref={ref}>
        <torusKnotGeometry args={[1.3, 0.5, 256, 64]} />
        <meshNormalMaterial />
      </mesh>
    </>
  )
}

export default function App() {
  return (
    <div style={{ position: "relative",height: 600 }}>

    <Canvas>
      <color args={['#f0f0ff']}  />
      <ambientLight />
      <Thing />
    </Canvas>
    </div>
  )
}
