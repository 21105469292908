import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative pt-40`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-primary-500`;

const PrimaryButtonn = tw(
  PrimaryButtonBase
)`mt-12 inline-block w-48 h-12 mx-12 tracking-wide text-center py-2`;
const Headingg = tw(SectionHeading)`w-full`;

export default ({
  subheading = "Ready to elevate your business website?",
  heading = (
    <>
      Feel free to <span tw="text-red-200">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Our team is always ready to answer your questions or concerns, we pride ourselves in having phenomenal customer service and client relations. Use the links below to contact us, so that we can help you get started with your future website!",
  submitButtonText = "Call Us",
  Button2Text = "Email Us",

  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <Headingg>
        <span tw="text-primary-800">Contact </span>{" "}
        <span tw="text-red-800">Us</span>
      </Headingg>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <PrimaryButtonn
              href="mailto:vurhddesigns@gmail.com"
              target="_blank"
              as="a"
            >
              {Button2Text}
            </PrimaryButtonn>
            <PrimaryButtonn href="tel:416-871-9292" target="_blank" as="a">
              {submitButtonText}
            </PrimaryButtonn>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
