import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "User Interface Design",
      description:
        "We prioritize the design and look of the overall website. A more attractive website typically translates to greater user connection and conversion. Our websites feature modern components and eye-catching features.",
    },
    {
      imageSrc: SupportIconImage,
      title: "Responsive Design Strategies",
      description:
        "Using open-source web framework's like ReactJs and Wordpress, we design responsive websites that are both compatible and attractive on all devices.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Visual Appeal",
      description:
        "Our sites are designed to appeal to all customer demographics, with the use of eye-catching images, design elements, and more.nb",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Analytics Integration",
      description:
        "All of our websites are created with advanced analytical and data generation capabilities, allowing our clients to track user analytics including demographics, origin, and more.",
    },
    {
      imageSrc: FastIconImage,
      title: "Search Engine Optimization",
      description:
        "Your website comes optimized for high search visibility(Google and Yahoo Bing), with embedded search-friendly content and keywords.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "User Experience Streategies",
      description:
        "The User Experience builds on the core fundamentals of any website. With the use of interactive components, animations, and user friendly design structures, our websites are fast, effeceint, and simple to use.",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          <span tw="text-primary-800">What Our Websites</span>{" "}
          <span tw="text-red-800">Feature</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer" color="green">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
