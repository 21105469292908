import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

import TextLoop from "react-text-loop";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full mb-8`;
const Description = tw(Subheading)`w-4/5  text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  subheading = "Serving Businesses of All Sizes",
  description = "We are a Web-Design business that specializes in helping local businesses achieve their digital marketing goal. With years of web-design and high-level programming experience, we offer our clients the best services with the shortest turn-around times in the industry!",
}) => {
  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && (
          <Subheading>
            <span tw="text-green-200">{subheading}</span>
          </Subheading>
        )}

        <Heading>
          <span tw="text-primary-800">
            Most{" "}
            <TextLoop>
              <div>Dependable</div>
              <div>Creative</div>
              <div>Authentic</div>
              <div>Professional</div>
              <div>Effective</div>
              <div>Valuable</div>
            </TextLoop>
          </span>{" "}
          <span tw="text-red-800">Web-Design Service</span>
        </Heading>

        {description && (
          <Description>
            <span tw="text-primary-800">{description}</span>
          </Description>
        )}
        <VerticalSpacer />
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
