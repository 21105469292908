import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl as ContentBase,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

const Container = tw(ContainerBase)`bg-black text-gray-100 -mx-8 px-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 bg-primary-800 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl text-orange-800 font-bold uppercase tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between bg-primary-800 rounded`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-base line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-100 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t border-red-100 lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-gray-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-black tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-bold tracking-wider w-full text-orange-100 bg-gray-800 px-6 py-4 rounded hover:bg-gray-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

export default ({
  subheading = "",
  heading = "Affordable Pricing",
  description = "Our plans include everything a business needs to create an effective, modern website to represent their brand. Additional requests such as, extra site pages, and revisions will be charged as add-ons.",
  plans = null,
  primaryButtonText = "Get Started Today",
}) => {
  const defaultPlans = [
    {
      name: "Lite",
      price: ["", "Contact for more detail"],
      oldPrice: "",
      description:
        "Perfect for businesses with moderate to low digital traffic",
      features: [
        "3 Site Pages",
        "1 Hr Brainstorm",
        "Theme Customization",
        "Responsive Site",
        "Social Media Add On",
        "Google Analytics",
        "Google Console",
        "SEO Layout",
        "1 Round of Revisions",
      ],
      url: "#contact_us",
      featured: "Most Popular",
    },
    {
      name: "Regular",
      price: ["", "Contact for more detail"],
      oldPrice: "",
      description:
        "Perfect for businesses that require a strong online presence ",
      features: [
        "6 Site Pages",
        "1 Hr Brainstorm",
        "Theme Customization",
        "Responsive",
        "Social Media Add On",
        "Google Analytics ",
        "Google Console",
        "SEO Layout",
        "2 Rounds of Revisions",
        "Google Maps",
        "Contact/Mailing Form",
      ],
      url: "#contact_us",
    },
    {
      name: "Grand",
      price: ["", "Contact for more detail"],
      oldPrice: "",
      description:
        "Perfect for businesses with moderate to high online traffic ",
      features: [
        "10 Site Pages",
        "1 Hr Brainstorm",
        "Theme Customization",
        "Responsive",
        "Social Media Add On",
        "Google Analytics",
        "Google Console",
        "SEO Layout",
        "3 Rounds of Revisions",
        "Google Maps ",
        "Contact/Mailing Form",
        "1 Hr Site Review",
        "Favicon Setup",
        "Animations",
      ],
      url: "#contact_us",
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>
            {" "}
            <span tw="text-primary-800">Effective</span>{" "}
            <span tw="text-red-800">Pricing</span>
          </Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                  {plan.featured && (
                    <span className="featuredText">{plan.featured}</span>
                  )}
                </span>
                <div className="pricingContainer">
                  <span className="currentPrice">
                    <span className="bigText">{plan.price[0]}</span>
                    {plan.price[1]}{" "}
                  </span>
                  {plan.oldPrice && (
                    <span className="oldPrice">{plan.oldPrice}</span>
                  )}
                </div>
                <p className="description">{plan.description}</p>
                <p className="description">
                  Extra Site Page:$100
                  <br />
                  Additional Revisions:$150
                  <br />
                  Additional changes requested after project completion: $30/hr
                </p>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <span className="text">{feature}</span>
                  </li>
                ))}
              </PlanFeatures>
              <PlanAction>
                <ActionButton as="a" href={plan.url}>
                  {primaryButtonText}
                </ActionButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
