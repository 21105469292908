import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(
  Container
)`-mx-8 px-8 bg-black text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-primary-100 mb-4`;
const Heading = tw(SectionHeading)`text-primary-700`;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-primary-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "If you have any additional questions, please contact us!",
  faqs = [
    {
      question: "How do I get started ?",
      answer:
        "Once you've decided to make your website with VURHD Designs, we'll draft an agreement contract for the services offered. The client and designer will then have a virtual meeting to discuss website themes, and content direction. At this point the client will express any hesitancies and concerns. The designed will then follow up with the client within 5-7 days with a completed draft, which will be revised and edited for any client adjustments.",
    },
    {
      question: "How much does it cost ?",
      answer:
        "Our cost brackets for each plan, and the included services are included in the Pricing Page. Our prices are completely inclusive, and include everything a business needs to design a strong website. ",
    },
    {
      question: "Are there any recurring fees ?",
      answer:
        "No! Unlike other web-design service providers, we strive to make our plans affordable and hassle-free. You only pay once, for the creation of your website. However, if you chose to re-design or change your website at a later date, additional fees will be charged to accomadate for developer and esigner costs.",
    },
    {
      question: "Where can I reach you for support ?",
      answer:
        "Our various contact methods are listed under our Contact Us section. We are awlays available during business hours, and often during off-hours to meet our clients needs. If you need an urgent response, you can call us and we will get back to you as soon as possible.",
    },
    {
      question: "How long does it take to create a new site ? ",
      answer:
        "Our complete website development portion takes only 5-7 days! Once the client has completed all initiation requirements, we start working right-away on your future website. However, often time clients choose to make multiple revisions after the intial publishing-draft, which may delay the overall timeline.",
    },
    {
      question: "Am I able to guide the design process ?",
      answer:
        "Ofcourse! We always welcome client input and suggestion, so that we can build a website that is well-equiped to meet client needs. Clients are also provided with revision privilleges, allowing clients to make changes to their published websites if needed.",
    },
  ],
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 },
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              marginTop: "16px",
              display: "block",
            },
            collapsed: {
              opacity: 0,
              height: 0,
              marginTop: "0px",
              display: "none",
            },
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>
            {" "}
            <span tw="text-primary-800">Frequently Asked</span>{" "}
            <span tw="text-red-800">Questions</span>
          </Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
