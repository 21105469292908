import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import MessengerCustomerChat from "react-messenger-customer-chat";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import TestimonialComponent from "components/Site_Components/recent_potfolio_homepage";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import Typical from "react-typical";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as HandleIcon } from "images/handle-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import Contactus from "components/Site_Components/contact_us_mainpage.js";

import useInView from "@owaiswiz/use-in-view";
import WhoWeAre from "components/Site_Components/whoweare_textcomponent.js";
import App from "./pages/animation/App.js";
import Services from "components/Site_Components/website_features";
import Servicesimgcard from "components/Site_Components/services_card_img";
import FAQ from "components/faqs/TwoColumnPrimaryBackground";
import Footer from "components/Site_Components/footer_mainpage";
import Servicefeatures from "components/Site_Components/servicefeatures.js";
import Pricing from "components/Site_Components/pricing_mainpage.js";
/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 font-bold lg:mt-0 transition duration-300  pb-1 border-b-2 mr-12 text-red-800 border-red-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
  NavLink
)`text-primary-100 bg-orange-100 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
const HeroRow = tw(
  Row
)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const TextColumn = tw(
  Column
)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(
  HeadingBase
)`text-left lg:text-left text-primary-100 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-left lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-start lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

const ComponentsContainer = tw.div`mt-24`;
const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
const ComponentName = tw.h6`text-lg`;
const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

export default ({
  features = null,
  heading = " Website Design Solutions",
  description = "A strong website stands at the core of any digital-marketing strategy. Your website acts as a sales rep and spokesperson for your brand. It is now more important than ever to have an impactful digital presence, to help meet your business goals. Let us at VURHD Designs, help you enhance your marketing portfolio.  ",
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "G-WVML0XZG13");

  features = features || [
    `All Growing Businesses`,
    `E-Commerce`,
    `Real Estate`,
    "1-Wk Turnaround",
    "Comprehensive Review",
    "Search Engine Optimized",
  ];

  return (
    <AnimationRevealPage disabled>
      <Container tw=" bg-black -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Container id="Home">
            <NavRow>
              <LogoLink href="/">VURHD DESIGNS</LogoLink>
              <div tw="flex flex-wrap justify-start lg:justify-end items-center -mr-12">
                <NavLink href="#Website_features">Services</NavLink>
                <NavLink target="_blank" href="#pricing">
                  Pricing
                </NavLink>
                <NavLink href="#recent_project">Recent Projects</NavLink>
                <div tw="md:hidden flex-100 h-0"></div>
                <PrimaryNavLink href="#contact_us">
                  Hire Us Today!
                </PrimaryNavLink>
              </div>
            </NavRow>
            <HeroRow>
              <TextColumn>
                <Heading as="h1">
                  <Typical
                    steps={[
                      "Effective",
                      3000,
                      "",
                      1000,
                      "Fast",
                      3000,
                      "",
                      1000,
                      "Unique",
                      2500,
                      "",
                      1000,
                    ]}
                    loop={Infinity}
                  />
                  {heading}
                </Heading>
                <Description>{description}</Description>
                <FeatureList>
                  {features.map((feature, index) => (
                    <Feature key={index}>
                      <FeatureIcon />
                      <FeatureText>{feature}</FeatureText>
                    </Feature>
                  ))}
                </FeatureList>
              </TextColumn>
              <TextColumn>
                <App />
              </TextColumn>
            </HeroRow>
          </Container>
          <WhoWeAre />
          <Container id="Service_Features">
            <Servicefeatures />
          </Container>
          <Container id="Website_features">
            <Services />
          </Container>
          <Container id="package_features">
            <Servicesimgcard />
          </Container>
          <Container id="pricing">
            <Pricing />
          </Container>
          <Container id="recent_project">
            <TestimonialComponent />
          </Container>
          <Container id="FAQ">
            <FAQ />
          </Container>
          <Container id="contact_us">
            <Contactus />
            <Footer />
          </Container>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

const BlocksRenderer = ({ blocks }) => {
  const [lastVisibleBlockIndex, setLastVisibleBlockIndex] = useState(0);

  const updateLastVisibleBlockIndex = (index) => {
    console.log("LAST WAS ", lastVisibleBlockIndex);
    if (index > lastVisibleBlockIndex) setLastVisibleBlockIndex(index);
  };

  return (
    <ComponentsContainer>
      {blocks.map(
        (block, index) =>
          lastVisibleBlockIndex + 1 >= index && (
            <Block
              key={index}
              components={block}
              notifyIsVisible={() => updateLastVisibleBlockIndex(index)}
            />
          )
      )}
    </ComponentsContainer>
  );
};

const Block = ({ notifyIsVisible, components }) => {
  const offset = 30;
  const [ref, inView] = useInView(offset);

  useEffect(() => {
    if (inView) notifyIsVisible();
  }, [inView, notifyIsVisible]);

  const ResizeHandle = (
    <ResizeHandleButton>
      <HandleIcon tw="w-4 h-4 text-gray-600" />
    </ResizeHandleButton>
  );

  const componentBlockRefs = {};

  const updateComponentBlockIframeHeight = (iframe) => {
    iframe.style.height = "auto";
    iframe.style.height =
      iframe.contentWindow.document.body.scrollHeight + "px";
  };

  return (
    <div ref={ref} tw="mt-32">
      <ComponentsType>{components.type}</ComponentsType>
      <Components>
        {Object.values(components.elements).map((component, componentIndex) => (
          <Component key={componentIndex}>
            <ComponentHeading>
              <ComponentName>{component.name}</ComponentName>
              <ComponentPreviewLink
                className="group"
                href={component.url}
                target="_blank"
              >
                View Live Demo{" "}
                <ArrowRightIcon tw="transition duration-300 transform group-hover:translate-x-px ml-2 w-4 h-4" />
              </ComponentPreviewLink>
            </ComponentHeading>
            <ComponentContent>
              <ResizableBox
                minWidth={310}
                default={{
                  width: "100%",
                  height: "100%",
                }}
                bounds="parent"
                disableDragging={true}
                enableResizing={{ right: true }}
                resizeHandleComponent={{ right: ResizeHandle }}
                resizeHandleWrapperClass={`resizeHandleWrapper`}
                onResize={() =>
                  updateComponentBlockIframeHeight(
                    componentBlockRefs[component.url]
                  )
                }
              >
                <iframe
                  src={component.url}
                  title="Hero"
                  width="100%"
                  ref={(ref) => (componentBlockRefs[component.url] = ref)}
                  onLoad={(e) => updateComponentBlockIframeHeight(e.target)}
                />
              </ResizableBox>
            </ComponentContent>
          </Component>
        ))}
      </Components>
    </div>
  );
};

/*
To Make Notification Banner at Top:
            <UpdateNotice>
              <UpdateNoticeIcon />
              Last updated on 20th April, 2021 - Added support for React v17 and TailwindCSS v2!
            </UpdateNotice>
*/

/*
<SectionContainer id="landingPageDemos">
            <SectionHeading>Landing Pages</SectionHeading>
            <SectionDescription>
              We have {noOfLandingPages} premade landing pages. Click on the
              "View Live Demo" button to see them in action. Customizing or
              Creating your own custom landing page is really simple by using
              our UI components.
            </SectionDescription>
            <PreviewCards>
              {Object.entries(landingPages).map(([pageName, page], index) => (
                <PreviewCardContainer key={index}>
                  <PreviewCard
                    initial="rest"
                    animate="rest"
                    whileHover="hover"
                    href={page.url}
                    target="_blank"
                  >
                    <PreviewCardImageContainer>
                      <PreviewCardImage
                        transition={{ type: "tween" }}
                        variants={previewImageAnimationVariants}
                        imageSrc={page.imageSrc}
                      />
                    </PreviewCardImageContainer>
                    <PreviewButton>View Live Demo</PreviewButton>
                  </PreviewCard>
                </PreviewCardContainer>
              ))}
            </PreviewCards>
          </SectionContainer>
          <SectionContainer>
            <SectionHeading>Inner Pages</SectionHeading>
            <SectionDescription>
              We also provide {noOfInnerPages} additional inner pages for your
              various needs like a signup, login, pricing, about us, contact,
              blog etc. To view them in action click the "View Live Demo"
              button.
            </SectionDescription>
            <PreviewCards>
              {Object.entries(innerPages).map(([pageName, page], index) => (
                <PreviewCardContainer key={index}>
                  <PreviewCard
                    initial="rest"
                    animate="rest"
                    whileHover="hover"
                    href={page.url}
                    target="_blank"
                  >
                    <PreviewCardImageContainer>
                      <PreviewCardImage
                        transition={{ type: "tween" }}
                        variants={
                          !page.scrollAnimationDisabled &&
                          previewImageAnimationVariants
                        }
                        imageSrc={page.imageSrc}
                      />
                    </PreviewCardImageContainer>
                    <PreviewButton>View Live Demo</PreviewButton>
                  </PreviewCard>
                </PreviewCardContainer>
              ))}
            </PreviewCards>
          </SectionContainer>

          <SectionContainer id="componentDemos">
            <SectionHeading>Component Blocks</SectionHeading>
            <SectionDescription>
              We also provide {noOfComponentBlocks} components along with the
              premade landing pages so you can create your own landing page
              within minutes as you see fit. You can combine these components to
              create 1000s of unique attractive web pages.
              <span tw="block text-sm text-gray-500 mt-2">
                (Preview Panel below inspired by Tailwind UI)
              </span>
            </SectionDescription>
            <BlocksRenderer blocks={Object.values(blocks)} />
          </SectionContainer>
          */
