import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature1 from "components/features/TwoColWithButton.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Team from "components/cards/ProfileThreeColGrid";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
const Name = "Smile Matters Dentistry";
const Description =
  "At Smile Matters your comfort is our top priority and we are excited to see you and help you have the best and most pleasant dental experience ever. Our Mission is to provide Affordable Dentist in Brampton. Our goal is to provide personal, affordable, cost effective dentistry.";
const Description2 =
  "Our only objective is to see you smiling not just for the moment but for your whole lifetime. We always make sure that we are up-to-date to serve you and your children providing high-class services at affordable prices. Dr Yachna Dua at Smile Matters Dentistry is widely prominent in community for her passion for cosmetic dentistry and provides our seniors affordable dentures. Dr Dua is also known for her commitment to treat young adults and kids in the community.";
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = "Our Team at Smile Matters Dentistry";
export default () => (
  <AnimationRevealPage>
    <Hero Name={Name} />
    <MainFeature Name={Name} description2={Description} />
    <Team subheading={Heading} />
    <Features Name={Name} />
    <MainFeature2 description={Description2} />
    <MainFeature1
      subheading={<Subheading>About Us</Subheading>}
      heading="We Are an Experienced Team"
      description="Our team of professionals care about our patients, and we strive to offer the highest standard of care. We have lots of experience and proudly serve our community everyday."
      buttonRounded={false}
      primaryButtonText="Contact Us"
      imageSrc="https://images.unsplash.com/photo-1600170311833-c2cf5280ce49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
    />
    <MainFeature1
      subheading={<Subheading>Our Vision</Subheading>}
      heading="Best Quality Of Care"
      description="We take pride in offer great care to our valued patients. We offer great services, and incentives to our patients, and look forward to helping everyone for their dental needs!"
      buttonRounded={false}
      primaryButtonText="Contact Us"
      imageSrc="https://images.unsplash.com/photo-1593022356769-11f762e25ed9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80"
      textOnLeft={false}
    />{" "}
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-red-500">Love Us.</span>
        </>
      }
      description="Here are what some of our amazing customers are saying about our marketing professionals. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1606811951341-756fdd437682?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
          quote:
            "This is a sample testimonial page for reviews, your business has recieved. Your final website will include numerous features and live reviews from Google. Our sites are also low maintenance and hassle-free!",
          customerName: "EXAMPLE REVIEW",
          customerTitle: "Local Reviewer",
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1588776814546-1ffcf47267a5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
          quote:
            "This is a sample testimonial page for reviews, your business has recieved. Your final website will include numerous features and live reviews from Google. Our sites are also low maintenance and hassle-free!",
          customerName: "EXAMPLE REVIEW",
          customerTitle: "Local Reviewer",
        },
      ]}
      textOnLeft={true}
    />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-red-500">Questions ?</span>
        </>
      }
    />
    <Blog />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
